export const FROZEN_NETWORKS = [
  "0x12657128d7fa4291647eC3b0147E5fA6EebD388A",
  "0xF2320a5371DA3CB967B9355597AA7adEc17E4220",
  "0x18BDC736b23Ff7294BED9fa988a1443357C7B0ed",
  "0x0A6f0C541Be542c098B7Ee03C9C634f20BCf8422",
  "0x01564a4b7C327c84763D7fCf629B02584AE72973",
  "0x8B2c0e43F69a8a25c94510cC85138260bb24e381",
  "0x7A6049b3A96E7a6D68e6e6813a19594Ea336d080",
  "0xB57C960Ee760cDa8184C9C9d9b7D0FeBE4557616",
  "0x8306e229ea6Edf3aD2670681912CcC0C338f7963",
  "0x70d4db4eC56b398D7cbA3666835490cb0e39f008",
  "0x049D814ED22bEA4394aC2Ec48bace8A1e31BC376",
  "0xc3790A6df080381ebf3eF9BeB35e6b70431bB420",
  "0xa81DE4DA252E012a5e8b0Fe1FE91BB0a55Dc5f9d",
  "0xB365694dB0961Fe81cA5e296c1250d24a4Ee627f",
  "0x1A64Bd5e956DA93E912CB7eb9b00B8D2Bc9015F9",
  "0x714dabD79B7962dA47c8fEA446064523CE0456fE",
  "0xC0f1030d83AEEF1616deef060114E31B3A25De81",
  "0x15933718E2e3478e756C13d516f7f4f4F749b3D6",
  "0x77626459240b8105DeC70dC97D61441924f166Df",
  "0x264B1775Ce5d2831608575974CfB9EB9F8F659f5",
  "0xb2B3A0E958e3eEb333b734ABF34ACA2dB5b94B8F",
  "0xA31fA3D8DaEBc1d7fb91a6F5F6805873dC958Ff2",
  "0x603f22B1D3854b985E0a61Ce4c0349534a0e5673",
  "0x5F74eD72158566c05328Eb815544461bD0b45116",
  "0x7Ac3fC1C428df99D81d9b644106FF67c44A155aA",
  "0xef5bC01da9AC8B32F78dC847D84454E48510Ef72",
  "0x97668E07c122f7701b804A4eA6fd196F1e264423",
  "0x57c4a2cCF2CC99f3dF7855465d1528C110Ff4F14",
  "0xa809D5e740AA324FF94751836b1d7Aa6BA2de81E",
  "0xF455c46823Cdb5A899217A0f085525874bA194A8",
  "0xc8a0a3aA3A98F27198De126379386D8aA7D01950",
  "0xCaa5B04e6E44EC3106EEBdAAd7D6C7bE9370Be32",
  "0x2421A2ea7B24b5D26177B78afcf8F4B40255324d",
  "0x2aB3f469D041aE68F45b3C255B7CB46370FfffF5",
  "0x03FE2A6a589e33b7d3cD8208705092BE572A76d1",
  "0xFe54Cbb23414467CcC052dACA1ecc908745D71Eb",
  "0x4D4dd71A818D4F7d3349F936382ea70979123F81",
  "0xCC6039e0681548002bcF39bf65d444beD65980B0",
  "0x821b770a4b5D1228322B2A2Bb29354b5faabD29F",
  "0x86D50E94e6Ba4B99C5c19439c13a07E5B7c231f9",
  "0x6212C2783B2acCA8468b1befb19a4aAccb3c4970",
  "0x4B1Ce992B04539786DF19dDC416490cd6595DB01",
  "0x8588173E0322089Efe851b14d041aC15AC991C07",
  "0xcb02a08a72Cb459fE41E987065fE1E2D7438E64E",
  "0x0FbD56e384826246dC795C1A1951c781184F8faa",
];

export default FROZEN_NETWORKS;
